<template>
  <!--srcviewscompanydetailsRiskriskvuehtmlStart-->

  <div id="defaultId1" class="ugtQvFr">
    <div id="g42509" class="uzdBfTN">
      <div id="g10900" class="ucaab9">
        <div id="g3ec8c" class="u2f28f">Julian Chen</div>
        <svg
          id="idP7GleG"
          @click="star = !star"
          class="icon uWGaRre"
          t="1719561137140"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="1883"
          width="35"
          height="35"
        >
          <path
            id="idtTPIM9"
            d="M925.4 498.5c25-24.3 33.8-60 23-93.1-10.8-33.1-38.9-56.9-73.4-61.9l-173.3-25.2c-13.4-2-24.9-10.4-30.8-22.4l-77.5-157c-15.4-31.3-46.7-50.7-81.5-50.7s-66.1 19.4-81.5 50.7l-77.5 157c-6 12.1-17.5 20.5-30.8 22.4l-173.3 25.1c-34.5 5-62.6 28.8-73.3 61.9-10.7 33.1-1.9 68.8 23 93.1l125.3 122.3 0.1 0.1c9.7 9.3 14 22.8 11.7 36.1L206 829.5c-5.9 34.4 7.9 68.4 36.1 88.9 16 11.6 34.6 17.5 53.4 17.5 14.4 0 29-3.5 42.4-10.5l155-81.5c11.9-6.3 26.1-6.3 38 0l155 81.5c30.8 16.2 67.5 13.5 95.6-6.9 28.2-20.5 42.1-54.6 36.2-88.9l-29.6-172.5c-2.2-13.3 2.2-26.8 11.8-36.2l125.5-122.4z"
            :fill="star ? '#FF6A6A' : '#f5f5f5'"
            p-id="1884"
          ></path>
        </svg>
      </div>
      <div id="gadd2b" class="ucf19b">
        <div id="gf777b" class="uce20c">
          <div id="g2071d" class="ude0ed">Name</div>
          <div id="g660de" class="u26464">Julian Chen</div>
        </div>
        <div id="ccae96" class="uce20c">
          <div id="ce7c22" class="ude0ed">Other Name</div>
          <div id="cc393b" class="u26464">陈晓东</div>
        </div>
        <div id="c6a62d" class="uce20c">
          <div id="caeca3" class="ude0ed">Gender</div>
          <div id="cfbae5" class="u26464">Male</div>
        </div>
        <div id="c91478" class="uce20c">
          <div id="c3bdb8" class="ude0ed">Age</div>
          <div id="c78576" class="u26464">51</div>
        </div>
        <div id="c643ad" class="uce20c">
          <div id="cbe20e" class="ude0ed">Birth Date</div>
          <div id="ce784c" class="u26464">12-May-1969</div>
        </div>
        <div id="c5d632" class="uce20c">
          <div id="c48054" class="ude0ed">Geographic Location</div>
          <div id="c3e0ca" class="u26464">China</div>
        </div>
      </div>
    </div>
    <div id="g15b7a" class="table_X utSSmth">
      <card id="g31b8a" class="u61eb0" :isShowLabel="true" label="Name Info">
        <el-table
          id="c96c5c"
          class="uvnONjL u6cmzvV"
          :data="infoData"
          empty-text="No Data"
        >
          <el-table-column
            id="c6f62a"
            class="uMxWZAr uUMivU9"
            prop="type"
            label="Name Type"
          >
          </el-table-column>
          <el-table-column
            id="c06b83"
            class="uXGOdre uCf7SFQ"
            prop="subtype"
            label="Name Subtype"
            width
          >
          </el-table-column>
          <el-table-column
            id="c89b15"
            class="u5UjLPj uRSjtBj"
            prop="name"
            label="Name"
            width
          >
          </el-table-column>
        </el-table>
      </card>
      <card
        id="c175cd"
        class="u61eb0"
        :isShowLabel="true"
        label="Inauguration & Address Info"
      >
        <el-table
          id="c69439"
          class="uvnONjL u6cmzvV u68dc3"
          :data="InaugurationData"
          empty-text="No Data"
        >
          <el-table-column
            id="c27861"
            class="uMxWZAr uUMivU9"
            prop="type"
            label="Name Type"
          >
          </el-table-column>
          <el-table-column
            id="c093cb"
            class="uXGOdre uCf7SFQ"
            prop="country"
            label="Country Code"
            width
          >
          </el-table-column>
        </el-table>
        <el-table id="c7d1da" class="uvnONjL u6cmzvV" :data="addressData">
          <el-table-column
            id="c3567c"
            class="uMxWZAr uUMivU9"
            prop="address"
            label="Address"
            width="600"
          >
          </el-table-column>
          <el-table-column
            id="cf46e6"
            class="uXGOdre uCf7SFQ"
            prop="country"
            label="Country"
            width
          >
          </el-table-column>
          <el-table-column
            id="cb8df6"
            class="uMxWZAr uUMivU9"
            prop="code"
            label="Region, Postal Code"
          >
          </el-table-column>
          <el-table-column
            id="c6a0d7"
            class="uMxWZAr uUMivU9"
            prop="web"
            label="Source Website"
          >
          </el-table-column>
        </el-table>
      </card>
      <card
        id="c199d9"
        class="u61eb0"
        :isShowLabel="true"
        label="Sanction List"
      >
        <el-table
          id="c670b2"
          class="uvnONjL u6cmzvV uvW97MR"
          :data="SanctionData"
        >
          <el-table-column
            id="ced5e9"
            class="uMxWZAr uUMivU9 uJqCiEB"
            prop="list"
            label="List of Sanctions Involved"
          >
          </el-table-column>
          <el-table-column
            id="c8ea4d"
            class="uXGOdre uCf7SFQ ullZFo5"
            prop="startData"
            label="Start Date"
            width
          >
          </el-table-column>
          <el-table-column
            id="c4f731"
            class="u5UjLPj uRSjtBj u3B5qW8"
            prop="endData"
            label="End Data"
            width
          >
          </el-table-column>
        </el-table>
      </card>
      <card
        id="ce6d58"
        class="u61eb0 uwvJkTd"
        :isShowLabel="true"
        label="Source List"
      >
        <div id="ged5a7" class="ub9a39">
          <contentText
            id="g2802d"
            class="ubde41"
            label="Source Name"
            contentAlign="flex-start"
            titleWidth="132px"
            marginRight="30px"
          >
            <div id="g8490d" class="u6d598">
              https://www.bis.doc.gov/index.php/policy-guidance/lists-of-parties-of-concern/entity-list
            </div>
          </contentText>
          <contentText
            id="c7f993"
            class="ubde41 u1adf7"
            label="Profile Notes"
            contentAlign="flex-start"
            titleWidth="132px"
            marginRight="30px"
          >
            <div id="c77d10" class="u6d598">
              OFFICE OF FOREIGN ASSETS CONTROL (OFAC) NOTEs: Target Type Private
              company Efiecive Date (Cmlc) 02 Aug 2021 PurchaselSales For
              bivestment Dale (CMic) 03 Jun2022 lising Daie /CMlc 03 un 2021
              061032021 ssuane of Execuve Orer Addresina the Threat fom Secuies
              ivestmenis that Finance Ceain Companes of the Peole'Reoubic of
              China & related FAQs: introduction of Non-SDN Chinese
              Milanc!ndustial complex Companies list The President has sianed a
              new Executive Order Adresing the Threairom Securies lnvestments
              that Finance Cenain Companies of the People's Repubic of china.
              0FAc has pubished the folowing new Freguenly Asked Quesions (FAQS:
              898, 899900, 901,902.903, 904,905,and updated FAas 857,859,860,
              861,863,865, and 871. Bls NOTEs: License requirement: For alliems
              subject to the EAR, see ss 734.9(e) 1 and74411 ofthe EAR 2licanse
              revew polcy presumtion ot demlal Faderal Reisierctalon: 84FR
              22963. 512119 84FR43495. 812119: 85FR29853 5920:85 FR 36720.
              6182D85 FR 51603, 8/20120: 86 FR 71559, 12/17121; 87 FR 6026,
              213/22: 87 FR 8182, 2/14/22: 87 FR 21012, 4/11/22: 87 FR 55250,
              919/22.
            </div>
          </contentText>
          <contentText
            id="ca2003"
            class="ubde41"
            label="Link URL"
            contentAlign="flex-start"
            titleWidth="132px"
            marginRight="30px"
          >
            <svg
              id="idAz7elp"
              class="icon"
              t="1719559001740"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="5674"
              width="38"
              height
            >
              <path
                id="idngNXLR"
                d="M901.850593 926.476283a48.761858 48.761858 0 0 1-48.761859 48.761859H170.422718a48.761858 48.761858 0 0 1-48.761858-48.761859V48.762834a48.761858 48.761858 0 0 1 48.761858-48.761859h418.864363a48.761858 48.761858 0 0 1 34.620919 14.140939l263.801654 263.801654a48.761858 48.761858 0 0 1 14.140939 34.620919V926.476283z"
                fill="#EBECF0"
                p-id="5675"
              ></path>
              <path
                id="idfUA4Cc"
                d="M901.850593 926.476283v48.761859a48.761858 48.761858 0 0 1-48.761859 48.761858H170.422718a48.761858 48.761858 0 0 1-48.761858-48.761858v-48.761859a48.761858 48.761858 0 0 0 48.761858 48.761859h682.666016a48.761858 48.761858 0 0 0 48.761859-48.761859z"
                fill="#C1C7D0"
                p-id="5676"
              ></path>
              <path
                id="idPV6AiG"
                d="M24.137143 536.381417h975.237166v243.809291a48.761858 48.761858 0 0 1-48.761858 48.761859H72.899001a48.761858 48.761858 0 0 1-48.761858-48.761859v-243.809291z"
                fill="#FF5630"
                p-id="5677"
              ></path>
              <path
                id="idjUaVxW"
                d="M121.66086 536.381417V438.8577l-97.523717 97.523717h97.523717zM901.850593 536.381417l0.975237-97.523717 97.036098 97.523717H901.850593z"
                fill="#DE350B"
                p-id="5678"
              ></path>
              <path
                id="idNDgg37"
                d="M267.946434 585.143275h84.845634a57.051374 57.051374 0 0 1 41.935198 15.603795 55.1009 55.1009 0 0 1 16.091413 40.959961 55.588518 55.588518 0 0 1-16.091413 40.959961 59.001849 59.001849 0 0 1-43.398054 16.091413h-48.761858v76.556118H267.946434z m32.670446 81.919922h43.885672a42.422817 42.422817 0 0 0 25.843785-6.339041 23.893311 23.893311 0 0 0 7.801897-19.992362q0-24.868548-32.670445-24.868548h-44.860909zM434.71199 588.068987H511.755726a73.142787 73.142787 0 0 1 58.51423 25.356166 100.937047 100.937047 0 0 1 21.942836 68.266602 110.689418 110.689418 0 0 1-20.967599 69.729457A71.679932 71.679932 0 0 1 511.755726 780.190708H434.71199z m32.670445 158.963658H511.755726a43.398054 43.398054 0 0 0 36.083775-17.066651A75.093262 75.093262 0 0 0 560.517584 682.666992a70.704695 70.704695 0 0 0-13.65332-48.761859 48.761858 48.761858 0 0 0-37.546631-16.579031h-41.935198zM755.565018 618.788957h-100.937047v45.348529H755.565018v31.207589h-100.937047v81.919922h-32.670445v-190.171248H755.565018z"
                fill="#FFFFFF"
                p-id="5679"
              ></path>
              <path
                id="idveGZKK"
                d="M901.850593 312.564487v6.82666h-263.801654a48.761858 48.761858 0 0 1-48.761858-48.761858V0.000975a48.761858 48.761858 0 0 1 34.620919 14.140939l264.289272 263.801654a48.761858 48.761858 0 0 1 13.653321 34.620919z"
                fill="#C1C7D0"
                p-id="5680"
              ></path>
            </svg>
          </contentText>
        </div>
        <div id="c278c8" class="ub9a39">
          <contentText
            id="c1341c"
            class="ubde41"
            label="Source Name"
            contentAlign="flex-start"
            titleWidth="132px"
            marginRight="30px"
          >
            <div id="cf0819" class="u6d598">
              https://www.bis.doc.gov/index.php/policy-guidance/lists-of-parties-of-concern/entity-list
            </div>
          </contentText>
          <contentText
            id="c70eab"
            class="ubde41 u1adf7"
            label="Profile Notes"
            contentAlign="flex-start"
            titleWidth="132px"
            marginRight="30px"
          >
            <div id="cf0743" class="u6d598">
              OFFICE OF FOREIGN ASSETS CONTROL (OFAC) NOTEs: Target Type Private
              company Efiecive Date (Cmlc) 02 Aug 2021 PurchaselSales For
              bivestment Dale (CMic) 03 Jun2022 lising Daie /CMlc 03 un 2021
              061032021 ssuane of Execuve Orer Addresina the Threat fom Secuies
              ivestmenis that Finance Ceain Companes of the Peole'Reoubic of
              China & related FAQs: introduction of Non-SDN Chinese
              Milanc!ndustial complex Companies list The President has sianed a
              new Executive Order Adresing the Threairom Securies lnvestments
              that Finance Cenain Companies of the People's Repubic of china.
              0FAc has pubished the folowing new Freguenly Asked Quesions (FAQS:
              898, 899900, 901,902.903, 904,905,and updated FAas 857,859,860,
              861,863,865, and 871. Bls NOTEs: License requirement: For alliems
              subject to the EAR, see ss 734.9(e) 1 and74411 ofthe EAR 2licanse
              revew polcy presumtion ot demlal Faderal Reisierctalon: 84FR
              22963. 512119 84FR43495. 812119: 85FR29853 5920:85 FR 36720.
              6182D85 FR 51603, 8/20120: 86 FR 71559, 12/17121; 87 FR 6026,
              213/22: 87 FR 8182, 2/14/22: 87 FR 21012, 4/11/22: 87 FR 55250,
              919/22.
            </div>
          </contentText>
          <contentText
            id="c5cd75"
            class="ubde41"
            label="Link URL"
            contentAlign="flex-start"
            titleWidth="132px"
            marginRight="30px"
          >
            <svg
              id="c20186"
              class="icon ueCfuVf"
              t="1719559001740"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="5674"
              width="38"
              height
            >
              <path
                id="c847d5"
                class="upolvhf"
                d="M901.850593 926.476283a48.761858 48.761858 0 0 1-48.761859 48.761859H170.422718a48.761858 48.761858 0 0 1-48.761858-48.761859V48.762834a48.761858 48.761858 0 0 1 48.761858-48.761859h418.864363a48.761858 48.761858 0 0 1 34.620919 14.140939l263.801654 263.801654a48.761858 48.761858 0 0 1 14.140939 34.620919V926.476283z"
                fill="#EBECF0"
                p-id="5675"
              ></path>
              <path
                id="c263f3"
                class="uRtgEIi"
                d="M901.850593 926.476283v48.761859a48.761858 48.761858 0 0 1-48.761859 48.761858H170.422718a48.761858 48.761858 0 0 1-48.761858-48.761858v-48.761859a48.761858 48.761858 0 0 0 48.761858 48.761859h682.666016a48.761858 48.761858 0 0 0 48.761859-48.761859z"
                fill="#C1C7D0"
                p-id="5676"
              ></path>
              <path
                id="c6b2b3"
                class="uBmUx8x"
                d="M24.137143 536.381417h975.237166v243.809291a48.761858 48.761858 0 0 1-48.761858 48.761859H72.899001a48.761858 48.761858 0 0 1-48.761858-48.761859v-243.809291z"
                fill="#FF5630"
                p-id="5677"
              ></path>
              <path
                id="c8e595"
                class="uw2VbT1"
                d="M121.66086 536.381417V438.8577l-97.523717 97.523717h97.523717zM901.850593 536.381417l0.975237-97.523717 97.036098 97.523717H901.850593z"
                fill="#DE350B"
                p-id="5678"
              ></path>
              <path
                id="cfb40f"
                class="umxA5FV"
                d="M267.946434 585.143275h84.845634a57.051374 57.051374 0 0 1 41.935198 15.603795 55.1009 55.1009 0 0 1 16.091413 40.959961 55.588518 55.588518 0 0 1-16.091413 40.959961 59.001849 59.001849 0 0 1-43.398054 16.091413h-48.761858v76.556118H267.946434z m32.670446 81.919922h43.885672a42.422817 42.422817 0 0 0 25.843785-6.339041 23.893311 23.893311 0 0 0 7.801897-19.992362q0-24.868548-32.670445-24.868548h-44.860909zM434.71199 588.068987H511.755726a73.142787 73.142787 0 0 1 58.51423 25.356166 100.937047 100.937047 0 0 1 21.942836 68.266602 110.689418 110.689418 0 0 1-20.967599 69.729457A71.679932 71.679932 0 0 1 511.755726 780.190708H434.71199z m32.670445 158.963658H511.755726a43.398054 43.398054 0 0 0 36.083775-17.066651A75.093262 75.093262 0 0 0 560.517584 682.666992a70.704695 70.704695 0 0 0-13.65332-48.761859 48.761858 48.761858 0 0 0-37.546631-16.579031h-41.935198zM755.565018 618.788957h-100.937047v45.348529H755.565018v31.207589h-100.937047v81.919922h-32.670445v-190.171248H755.565018z"
                fill="#FFFFFF"
                p-id="5679"
              ></path>
              <path
                id="cb2aef"
                class="uMeeQBE"
                d="M901.850593 312.564487v6.82666h-263.801654a48.761858 48.761858 0 0 1-48.761858-48.761858V0.000975a48.761858 48.761858 0 0 1 34.620919 14.140939l264.289272 263.801654a48.761858 48.761858 0 0 1 13.653321 34.620919z"
                fill="#C1C7D0"
                p-id="5680"
              ></path>
            </svg>
          </contentText>
        </div>
        <div id="c50fc9" class="ub9a39">
          <contentText
            id="cc090c"
            class="ubde41"
            label="Source Name"
            contentAlign="flex-start"
            titleWidth="132px"
            marginRight="30px"
          >
            <div id="cf076b" class="u6d598">
              https://www.bis.doc.gov/index.php/policy-guidance/lists-of-parties-of-concern/entity-list
            </div>
          </contentText>
          <contentText
            id="c296b8"
            class="ubde41 u1adf7"
            label="Profile Notes"
            contentAlign="flex-start"
            titleWidth="132px"
            marginRight="30px"
          >
            <div id="c9696b" class="u6d598">
              OFFICE OF FOREIGN ASSETS CONTROL (OFAC) NOTEs: Target Type Private
              company Efiecive Date (Cmlc) 02 Aug 2021 PurchaselSales For
              bivestment Dale (CMic) 03 Jun2022 lising Daie /CMlc 03 un 2021
              061032021 ssuane of Execuve Orer Addresina the Threat fom Secuies
              ivestmenis that Finance Ceain Companes of the Peole'Reoubic of
              China & related FAQs: introduction of Non-SDN Chinese
              Milanc!ndustial complex Companies list The President has sianed a
              new Executive Order Adresing the Threairom Securies lnvestments
              that Finance Cenain Companies of the People's Repubic of china.
              0FAc has pubished the folowing new Freguenly Asked Quesions (FAQS:
              898, 899900, 901,902.903, 904,905,and updated FAas 857,859,860,
              861,863,865, and 871. Bls NOTEs: License requirement: For alliems
              subject to the EAR, see ss 734.9(e) 1 and74411 ofthe EAR 2licanse
              revew polcy presumtion ot demlal Faderal Reisierctalon: 84FR
              22963. 512119 84FR43495. 812119: 85FR29853 5920:85 FR 36720.
              6182D85 FR 51603, 8/20120: 86 FR 71559, 12/17121; 87 FR 6026,
              213/22: 87 FR 8182, 2/14/22: 87 FR 21012, 4/11/22: 87 FR 55250,
              919/22.
            </div>
          </contentText>
          <contentText
            id="cb6120"
            class="ubde41"
            label="Link URL"
            contentAlign="flex-start"
            titleWidth="132px"
            marginRight="30px"
          >
            <svg
              id="cd6c83"
              class="icon ueCfuVf"
              t="1719559001740"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="5674"
              width="38"
              height
            >
              <path
                id="c80fdc"
                class="upolvhf"
                d="M901.850593 926.476283a48.761858 48.761858 0 0 1-48.761859 48.761859H170.422718a48.761858 48.761858 0 0 1-48.761858-48.761859V48.762834a48.761858 48.761858 0 0 1 48.761858-48.761859h418.864363a48.761858 48.761858 0 0 1 34.620919 14.140939l263.801654 263.801654a48.761858 48.761858 0 0 1 14.140939 34.620919V926.476283z"
                fill="#EBECF0"
                p-id="5675"
              ></path>
              <path
                id="cf35ad"
                class="uRtgEIi"
                d="M901.850593 926.476283v48.761859a48.761858 48.761858 0 0 1-48.761859 48.761858H170.422718a48.761858 48.761858 0 0 1-48.761858-48.761858v-48.761859a48.761858 48.761858 0 0 0 48.761858 48.761859h682.666016a48.761858 48.761858 0 0 0 48.761859-48.761859z"
                fill="#C1C7D0"
                p-id="5676"
              ></path>
              <path
                id="ca747b"
                class="uBmUx8x"
                d="M24.137143 536.381417h975.237166v243.809291a48.761858 48.761858 0 0 1-48.761858 48.761859H72.899001a48.761858 48.761858 0 0 1-48.761858-48.761859v-243.809291z"
                fill="#FF5630"
                p-id="5677"
              ></path>
              <path
                id="c59178"
                class="uw2VbT1"
                d="M121.66086 536.381417V438.8577l-97.523717 97.523717h97.523717zM901.850593 536.381417l0.975237-97.523717 97.036098 97.523717H901.850593z"
                fill="#DE350B"
                p-id="5678"
              ></path>
              <path
                id="ce909d"
                class="umxA5FV"
                d="M267.946434 585.143275h84.845634a57.051374 57.051374 0 0 1 41.935198 15.603795 55.1009 55.1009 0 0 1 16.091413 40.959961 55.588518 55.588518 0 0 1-16.091413 40.959961 59.001849 59.001849 0 0 1-43.398054 16.091413h-48.761858v76.556118H267.946434z m32.670446 81.919922h43.885672a42.422817 42.422817 0 0 0 25.843785-6.339041 23.893311 23.893311 0 0 0 7.801897-19.992362q0-24.868548-32.670445-24.868548h-44.860909zM434.71199 588.068987H511.755726a73.142787 73.142787 0 0 1 58.51423 25.356166 100.937047 100.937047 0 0 1 21.942836 68.266602 110.689418 110.689418 0 0 1-20.967599 69.729457A71.679932 71.679932 0 0 1 511.755726 780.190708H434.71199z m32.670445 158.963658H511.755726a43.398054 43.398054 0 0 0 36.083775-17.066651A75.093262 75.093262 0 0 0 560.517584 682.666992a70.704695 70.704695 0 0 0-13.65332-48.761859 48.761858 48.761858 0 0 0-37.546631-16.579031h-41.935198zM755.565018 618.788957h-100.937047v45.348529H755.565018v31.207589h-100.937047v81.919922h-32.670445v-190.171248H755.565018z"
                fill="#FFFFFF"
                p-id="5679"
              ></path>
              <path
                id="c55875"
                class="uMeeQBE"
                d="M901.850593 312.564487v6.82666h-263.801654a48.761858 48.761858 0 0 1-48.761858-48.761858V0.000975a48.761858 48.761858 0 0 1 34.620919 14.140939l264.289272 263.801654a48.761858 48.761858 0 0 1 13.653321 34.620919z"
                fill="#C1C7D0"
                p-id="5680"
              ></path>
            </svg>
          </contentText>
        </div>
        <div id="g9fbed" class="uc2375 show_AllHeight">
          <div id="g44ec9" class="u1698b show_All">Show All</div>
        </div>
      </card>
      <card
        id="cb5c3f"
        class="u61eb0 u3917e"
        :isShowLabel="true"
        label="Associations List"
      >
        <el-table
          id="c354fc"
          class="uvnONjL u6cmzvV uJ8QPVE"
          :data="AssociationsData"
        >
          <el-table-column
            id="cca278"
            class="uMxWZAr uUMivU9 uM6XvOV"
            prop="id"
            label="Association ID"
          >
          </el-table-column>
          <el-table-column
            id="cf54ef"
            class="uXGOdre uCf7SFQ uVl9tus"
            prop="relation"
            label="Relation"
            width
          >
          </el-table-column>
          <el-table-column
            id="c87c56"
            class="u5UjLPj uRSjtBj u6v95VP"
            prop="name"
            label="Name"
            width
          >
          </el-table-column>
        </el-table>
      </card>
    </div>
  </div>
  <!--srcviewscompanydetailsRiskriskvuehtmlEnd-->
</template>
<script>
// interfaceCode
//importStart

import card from "../../../../components/gui_card/gui_card.vue";
import contentText from "../../../../components/text_content/index.vue";
//importEnd

export default {
  name: "",
  props: {
    // srcviewscompanydetailsRiskriskvuePropsStart
    // srcviewscompanydetailsRiskriskvuePropsEnd
  },
  mounted() {
    // defaultLoad
    // srcviewscompanydetailsRiskriskvueMountedStart
    this.init();

    // srcviewscompanydetailsRiskriskvueMountedEnd
  },

  components: {
    card,
    contentText,
  },

  data() {
    return {
      // insertData
      // srcviewscompanydetailsRiskriskvueDataStart

      // star
      star: true,
      // infoData
      infoData: [
        {
          type: "Primary",
          subtype: "OSN",
          name: "Julian Chen",
        },
        {
          type: "Primary",
          subtype: "OSN",
          name: "Julian Chen",
        },
        {
          type: "Primary",
          subtype: "OSN",
          name: "Julian Chen",
        },
        {
          type: "Primary",
          subtype: "OSN",
          name: "Julian Chen",
        },
        {
          type: "Primary",
          subtype: "OSN",
          name: "Julian Chen",
        },
      ],
      // InaugurationData
      InaugurationData: [
        {
          type: "Country of Affiliation",
          country: "China",
        },
        {
          type: "Country of Affiliation",
          country: "China",
        },
        {
          type: "Country of Affiliation",
          country: "China",
        },
        {
          type: "Country of Affiliation",
          country: "China",
        },
        {
          type: "Country of Affiliation",
          country: "China",
        },
      ],
      // addressData
      addressData: [
        {
          address: "Huawei Industrial Base,Bantian, Longgang Distirct",
          country: "China",
          code: "Shenzhen; Guangdong; 518129",
          web: "http://www.huawei.com/en/",
        },
        {
          address: "Bantian Huawei Base, Longgang District",
          country: "China",
          code: "Shenzhen; 518129",
          web: "http://www.huawei.com/en/",
        },
        {
          address: "Banxuegang Industrial Park, Buji Longgang",
          country: "China",
          code: "Shenzhen; Guangdong; 518129",
          web: "http://www.huawei.com/en/",
        },
        {
          address: "C1, Wuhan Future City, No.999 Gaoxin Ave.",
          country: "China",
          code: "Shenzhen; Guangdong; 518129",
          web: "http://www.huawei.com/en/",
        },
        {
          address: "No.1899 Xi Yuan Road, High-Tech West District",
          country: "China",
          code: "Shenzhen; Guangdong; 518129",
          web: "http://www.huawei.com/en/",
        },
      ],
      // SanctionData
      SanctionData: [
        {
          list: "BIS Entity List",
          startData: "16-May-2019",
          endData: "02-Aug-2021",
        },
        {
          list: "OFAC-Non-SDN CMiC ldentifiers List",
          startData: "16-May-2019",
          endData: "02-Aug-2021",
        },
        {
          list: "OFAC-Non-SDN Chinese Military-Industrial Complex Companies List",
          startData: "16-May-2019",
          endData: "02-Aug-2021",
        },
        {
          list: "US Department of Defense FY21 NDAA 1260H List",
          startData: "16-May-2019",
          endData: "02-Aug-2021",
        },
        {
          list: "US Department of Defense FY19 NDAA 889 List",
          startData: "16-May-2019",
          endData: "02-Aug-2021",
        },
      ],
      // AssociationsData
      AssociationsData: [
        {
          id: "3029046",
          relation: "Subsidiary",
          name: "Huawei Technologies Co.,Ltd",
        },
        {
          id: "3029046",
          relation: "Subsidiary",
          name: "Huawei Technologies Co.,Ltd",
        },
        {
          id: "3029046",
          relation: "Subsidiary",
          name: "华为技术有限公司",
        },
        {
          id: "3029046",
          relation: "Subsidiary",
          name: "华技有限公司",
        },
        {
          id: "3029046",
          relation: "Subsidiary",
          name: "華技有限公司",
        },
      ],
      // srcviewscompanydetailsRiskriskvueDataEnd
    };
  },
  methods: {
    // insertMethod
    // srcviewscompanydetailsRiskriskvueMethodStart
    //
    setThemeColor() {
      // this.$store.dispatch("app/setThemColor", "#8E86DD");
      // this.$store.dispatch("app/setSearchType", "risk");
    }, //
    init() {
      this.$store.dispatch("app/toggleMenu", false); // 强制关闭参数列表
      this.$store.dispatch("app/setTools", {
        name: "collect",
        key: "isActive",
        value: false,
      });
      this.$store.dispatch("app/setTools", {
        name: "saved",
        key: "isActive",
        value: false,
      });

      this.$store.dispatch("app/setTools", {
        name: "compare",
        key: "isActive",
        value: true,
      });
      this.$store.dispatch("app/setTools", {
        name: "update",
        key: "isActive",
        value: true,
      });

      this.$store.dispatch("app/setTools", {
        name: "download",
        key: "isActive",
        value: true,
      });
      this.$store.dispatch("app/setTools", {
        name: "reportError",
        key: "isActive",
        value: true,
      });
      this.$store.dispatch("app/setTools", {
        name: "share",
        key: "isActive",
        value: true,
      });
      // 添加 选中公司
    }, // default
    default() {},
    // srcviewscompanydetailsRiskriskvueMethodEnd
  },
  computed: {},
};
</script>
<style>
.srcviewscompanydetailsRiskriskvuecssStart {
}

.ugtQvFr {
  background-color: rgb(245, 245, 245);
}

.uzdBfTN {
  display: flex;
  height: 262px;
  background-color: rgb(142, 134, 221);
  padding-top: 33px;
  margin-bottom: 20px;
}

.ucaab9 {
  display: flex;
  flex-direction: row;
}

.u2f28f {
  color: #ffffff;
  font-size: 36px;
  margin-left: 85px;
  font-weight: 400;
}

.uWGaRre {
  margin-right: 28px;
  margin-top: 4px;
  margin-left: 30px;
}

.ucf19b {
  display: flex;
  justify-content: space-around;
  width: 0;
  flex: auto;
  margin-right: 15px;
}

.uce20c {
  display: flex;
  margin-top: 14px;
}

.ude0ed {
  color: #ffffff;
  margin-right: 10px;
  font-size: 18px;
  font-weight: 400;
}

.u26464 {
  color: #ffffff;
  font-size: 18px;
  font-weight: lighter;
}

.utSSmth {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin-top: -147px;
}

.u61eb0 {
  margin-bottom: 10px;
}

.u68dc3 {
  margin-bottom: 40px;
}

.uwvJkTd {
  padding-bottom: 0;
}

.ub9a39 {
  margin-top: 30px;
  border-top-style: solid;
  border-top-color: rgb(204, 204, 204);
  border-top-width: 1px;
}

.ubde41 {
  margin-top: 30px;
}

.u6d598 {
  font-size: 20px;
}

.u1adf7 {
  display: flex;
  align-items: flex-start;
}

.uc2375 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  margin-top: 30px;
  border-top-style: solid;
  border-top-color: rgb(204, 204, 204);
  border-top-width: 1px;
}

.u1698b {
  color: #1290c9;
  font-size: 20px;
}

.u3917e {
  margin-bottom: 0;
}

.srcviewscompanydetailsRiskriskvuecssEnd {
}
</style>